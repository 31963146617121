<template>
  <div>
    <b-dropdown
        variant="white"
        toggle-class="header-item"
        :dropleft="dropLeft"
        :dropup="dropUp"
    >
      <template v-slot:button-content>
        <img v-if="getCurrentLanguage() && getCurrentLanguage().flagUrl" class v-bind:src="getCurrentLanguage().flagUrl" alt="Header Language" height="16" />
      </template>

      <b-dropdown-item
          class="notify-item"
          v-for="(language, i) in $store.getters['translation/getLanguages']"
          :key="`Lang${i}`"
          :value="language"
          @click="setLanguage(language)"
          :link-class="{'active': language.id === $store.getters['translation/getCurrentLocale']}">
        <img :src="`${language.flagUrl}`" alt="Flag Icon" class="mr-1" height="12" />
        <span class="align-middle">{{ $store.getters["translation/getTranslation"]('ecat.language.' + language.countryCodeISO639_1) }}</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import {languageHelper} from "@/helpers/language-helper";
import {errorCatcher} from "@/helpers/error-catcher";

export default {

  props: {
    dropUp: {
      type: Boolean,
      default: false
    },

    dropLeft: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    async setLanguage(language) {
      // if (this.$store.getters("translation/getCurrentLocale") === language.id) {
      //   return;
      // }

      await this.$store.dispatch("translation/setCurrentLocale", language.id)
      await this.loadTranslations(language.id)
      await this.$root.$emit("change-language")
      await this.$root.$emit("ecat-reload-information-bar")

      const { countryCodeISO639_1 } = language;
      moment.locale(countryCodeISO639_1)
    },

    getCurrentLanguage() {
      return this.$store.getters['translation/getCurrentLanguage']
    },

    async loadTranslations(id) {
      try {
        const {data} = await axios.get(`/translation/by-language-id/${id}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        const translations = languageHelper.transformJSONToMap(JSON.parse(data.translations));
        await this.$store.dispatch("translation/setTranslations", translations);
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }

  },

  async mounted() {
    const language = await this.$store.getters["translation/getLanguage"]
    if (!language) {
      return;
    }

    moment.locale(language.countryCodeISO639_1)
  }

}
</script>